export const environment = {
  production: false,
  api: 'https://consolewebapi-dev.viggo.io',
  firebaseConfig: {
    apiKey: 'AIzaSyBpq40AOGf7_45LKK37djh0h9z9TLpgVSU',
    authDomain: 'viggo-console-dev.firebaseapp.com',
    databaseURL: 'https://viggo-console-dev.firebaseio.com',
    projectId: 'viggo-console-dev',
    storageBucket: 'viggo-console-dev.appspot.com',
    messagingSenderId: '118164539696',
    appId: '1:118164539696:web:361d953e5d8a6ff753e0f5',
    measurementId: 'G-HBG828G3F6',
  },
  financeManager: {
    elasticApi: 'https://1d698d826ad046079714a64f6be5cc07.europe-west3.gcp.cloud.es.io:9243',
    elasticApiKey: 'TVFRaGVZMEIyYUl0WVRkWkpRMXI6Zl9leG5WcFBTS3FJSUNBZjB5MGFzdw==',
    elasticIndexPrefix: 'finance-manager',
  },
  support: {
    elasticApi: 'https://1d698d826ad046079714a64f6be5cc07.europe-west3.gcp.cloud.es.io:9243',
    elasticApiKey: 'Y0xIM1gzZ0JZeVVkWHJMaW0wUlo6VXQ0U1hiQmlSWXlVc2U3d3V5Mlp3QQ==',
    elasticIndexPrefix: 'support',
  },
};
